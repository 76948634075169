export const env = {
  production: true,
  backendUrl: 'https://customer-consent.bmwna.com/consent-portal/api/v1/',
  cookieName: 'B_CONSENT',
  redirectBackUrl: 'https://customer-consent.bmwna.com/',
  isUSHub: true,
  isE2E: false,
  isDev: false,
  clientId: 'b558e823-ade8-4bdb-9655-464694de87a7',
};
